.container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px;
  padding: 56px 20px;
}

.detailsContainer > :nth-child(1) {
  flex-basis: 48%;
  flex-grow: 0;
  flex-shrink: 0;
  max-width: 408px;
  min-height: 290px;
  color: #fff;
  font-family: 'Lato', sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  position: relative;
}

.detailsContainer > :nth-child(1) > h3,
.detailsContainer > :nth-child(1) > p {
  font-family: 'Raleway', sans-serif;
}

.detailsButton {
  position: absolute;
  bottom: 0;
  background-color: #000000c7;
  color: #fff;
  width: 100%;
  text-align: center;
  font-size: 16px;
  padding: 10px 12px;
  border-radius: 8px;
  cursor: pointer;
}

.detailsBlock {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  column-gap: 5px;
}

.detailsText {
  font-weight: 700;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    grid-template-columns: repeat(2, 1fr);
    padding: 28px 20px;
  }

  .container > div {
    flex-basis: 100%;
    max-width: 100%;
    min-height: 341px;
    font-family: Lato;
    font-size: 20px;
    border-radius: 20px;
  }
}

@media screen and (max-width: 767px) {
  .container {
    /* display: flex; */
    /* flex-direction: column; */
    grid-template-columns: 1fr;
    padding: 28px 20px;
  }

  .container > div {
    flex-basis: 100%;
    width: 80%;
    max-width: none;
    font-family: 'Lato', sans-serif;
    font-size: 20px;
    border-radius: 20px;
    margin: 0 auto 20px auto;
  }

  .detailsContainer > :nth-child(1) {
    max-width: 100%;
  }
}

@media screen and (max-width: 640px) {
  .container > div {
    width: 90%;
  }
}
