@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.container {
  margin-bottom: 24px;
}

.dropdown {
  position: relative;
  user-select: none;
  width: 100%;
  height: 60px;
  padding: 1rem 32px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
}

.invalid,
.inActive {
  border: 1px solid red;
}

.valid {
  border: 1px solid #000;
  border-radius: 12px;
}

.active {
  border: 1px solid #000;
  border-radius: 12px;
}

.dropdownButton:focus {
  outline: none;
}

.dropdown .dropdownButton {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #000;
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  animation: appear 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.dropdown svg {
  width: 24px;
  height: 14px;
  cursor: pointer;
  transition: all 0.2s;
}

.dropdownButton > span {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.dropdown .dropdownContent {
  position: absolute;
  width: 100%;
  top: 110%;
  left: 0;
  box-sizing: border-box;
  z-index: 1;
  background: #ffffff;
  border-radius: 12px;
  min-height: 100%;
  max-height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
  text-align: left;
  -ms-overflow-style: none;
  scrollbar-width: none;
  outline: none;
  border: none;
  animation: appear 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;

  border: 1px solid var(--foundation-grey-g-40, #e0e0e0);
  background: #fff;
}

.dropdown .dropdownContent::-webkit-scrollbar {
  display: none;
}

.dropdown .dropdownContent .dropdownItem {
  padding: 18px 32px;
  cursor: pointer;
  transition: all 0.2s;
  position: relative;
  color: #000;
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
}

.dropdown .dropdownContent .dropdownItem:hover {
  background: #cafad2;
}

.dropdownItem2 {
  margin: 0;
  padding: 0.7rem 1rem;
  cursor: pointer;
  transition: all 0.2s;
  position: relative;
  font-family: Archivo;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  width: 100%;
  cursor: pointer;
}

.inputSection {
  height: 60px;
  padding: 0.5rem 0.5rem;
  position: sticky;
  top: 0;
  background: #ffffff;
  z-index: 1;
}

.inputSection > input {
  width: 100%;
  height: 100%;
  border-radius: 0px;
  text-indent: 8px;
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: #fcfcfc;
  border: 0.5px solid #d8dce9;
  outline: none;
  box-sizing: border-box;
}

.loadingContainer {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
}

.label {
  color: #000;
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 28px;
  display: block;
}

.label > span {
  color: var(--red);
}

.errorMessage {
  color: var(--red);
  font-family: Archivo;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  animation: appear 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
@media screen and (min-width: 767px) and (max-width: 1023px) {
  .dropdown {
    border-radius: 5px;
    width: 100%;
    height: 50px;
    padding: 0 22px;
  }

  .dropdown .dropdownButton {
    font-size: 14px;
    line-height: 23px;
  }

  .errorMessage {
    font-size: 10px;
    line-height: 18px;
  }

  .label,
  .inputSection > input,
  .dropdown .dropdownContent .dropdownItem {
    font-size: 14px;
    line-height: 23px;
  }
}

@media screen and (max-width: 767px) {
  .dropdown {
    border-radius: 5px;
    width: 100%;
    height: 50px;
    padding: 0 22px;
  }

  .dropdown .dropdownButton {
    font-size: 14px;
    line-height: 23px;
  }

  .errorMessage {
    font-size: 10px;
    line-height: 18px;
  }

  .label,
  .inputSection > input,
  .dropdown .dropdownContent .dropdownItem {
    font-size: 14px;
    line-height: 23px;
  }
}
