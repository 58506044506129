.container {
  min-height: calc(100vh - 10vh);
  background: linear-gradient(rgba(0, 143, 79, 0.7), rgba(0, 143, 79, 0.7)),
    url('../../Assets/Images/gmwHeroImage.jpeg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 40px;
  position: relative;
}

.heroText {
  margin: 60px 100px 0px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.heroText > h3 {
  font-family: Regular;
  text-transform: uppercase;
  color: #fff;
  font-size: 56px;
  font-weight: bold;
  margin-bottom: 3rem;
}

.heroText > h3 > span {
  background-color: #ffde00;
  color: #000;
  padding: 5px;
}

.heroText > p {
  color: #fff;
  font-size: 24px;
  font-style: italic;
  font-weight: 300;
}

.colorContainer {
  position: absolute;
  bottom: 0;
  left: 20%;
}

@media screen and (min-width: 768px) and (max-width: 920px) {
  .container {
    padding: 10px;
  }

  .logoContainer > img {
    width: 100px;
  }

  .heroText {
    margin: 100px 20px 0px 0px;
  }

  .heroText > h3 {
    font-size: 32px;
    margin-bottom: 1rem;
  }

  .heroText > p {
    font-size: 20px;
    margin-bottom: 3rem;
  }
}

@media screen and (max-width: 767px) {
  .container {
    padding: 10px;
  }

  .logoContainer > img {
    width: 100px;
  }

  .heroText {
    margin: 100px 20px 0px 0px;
  }

  .heroText > h3 {
    font-size: 32px;
    margin-bottom: 1rem;
  }

  .heroText > p {
    font-size: 18px;
    margin-bottom: 3rem;
  }
}
