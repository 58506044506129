.container {
  padding: 72px 120px;
  background: #08841b14;
}

.upperSection {
  display: flex;
  align-items: center;
}

.upperSection > div {
  flex-basis: 60%;
}

.upperSection > p {
  font-family: 'Raleway', sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 30.91px;
  text-align: right;
  flex-basis: 40%;
}

.lowerSection {
  margin-top: 90px;
  display: flex;
  align-items: stretch;
  gap: 1rem;
}

.lowerSection > div {
  border: 1px solid #000000;
  border-radius: 2rem;
  display: flex;
  justify-content: center;
}

.lowerSection > div:nth-child(1) {
  align-items: center;
  max-width: 543px;
  height: 604px;
  padding: 1rem;
}

.lowerSection > div:nth-child(2) {
  background: #ffffff;
  border: 1px solid #000000;
  font-family: Raleway;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  text-align: justified;
  color: #000000;
  flex-direction: column;
  padding: 1rem 4rem;
  flex: 1;
}

.lowerSection > div:nth-child(2) > p {
  margin-bottom: 2rem;
}

.lowerSection > div:nth-child(2) > button {
  width: 267px;
}

@media screen and (max-width: 767px) {
  .container {
    padding: 22px;
  }

  .upperSection {
    align-items: flex-start;
    flex-direction: column;
    gap: 1rem;
    padding: 0;
  }

  .upperSection > div,
  .upperSection > p {
    flex-basis: 100%;
  }

  .upperSection > p {
    font-size: 16px;
    line-height: 150%;
    text-align: left;
  }

  .lowerSection {
    margin-top: 30px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
  }

  .lowerSection > div {
    border: 1px solid #000000;
    border-radius: 2rem;
    display: flex;
    justify-content: center;
  }

  .lowerSection > div:nth-child(1) {
    align-items: center;
    max-width: 100%;
    height: 404px;
    padding: 1rem;
  }

  .lowerSection > div:nth-child(1) > img {
    height: 100%;
    width: 100%;
  }

  .lowerSection > div:nth-child(2) {
    background: #ffffff;
    border: 1px solid #000000;
    font-family: Raleway;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    padding: 1rem;
  }

  .lowerSection > div:nth-child(2) > p {
    margin-bottom: 2rem;
  }

  .lowerSection > div:nth-child(2) > button {
    width: 100%;
  }
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    padding: 22px;
  }

  .upperSection {
    align-items: flex-start;
    flex-direction: column;
    gap: 1rem;
    padding: 0;
  }

  .upperSection > div,
  .upperSection > p {
    flex-basis: 100%;
  }

  .upperSection > p {
    font-size: 16px;
    line-height: 150%;
    text-align: left;
  }

  .lowerSection {
    margin-top: 30px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
  }

  .lowerSection > div {
    border: 1px solid #000000;
    border-radius: 2rem;
    display: flex;
    justify-content: center;
  }

  .lowerSection > div:nth-child(1) {
    align-items: center;
    max-width: 100%;
    height: 404px;
    padding: 1rem;
  }

  .lowerSection > div:nth-child(1) > img {
    height: 100%;
    width: 100%;
  }

  .lowerSection > div:nth-child(2) {
    background: #ffffff;
    border: 1px solid #000000;
    font-family: Raleway;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    padding: 1rem;
  }

  .lowerSection > div:nth-child(2) > p {
    margin-bottom: 2rem;
  }

  .lowerSection > div:nth-child(2) > button {
    width: 100%;
  }
}
