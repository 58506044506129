.preferredProject {
  margin-bottom: 2rem;
}

.preferredProject > p:nth-child(1) {
  color: #000;
  font-family: 'Raleway', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.preferredProject > p:nth-child(2) {
  color: #000;
  font-family: 'Raleway', sans-serif;
  font-size: 16px;
  font-style: italic;
  font-weight: 300;
  line-height: normal;
  margin: 24px 0;
}

.preferredProject > div {
  border: 1px solid #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 3rem 1rem 2rem;
  color: #000;
  font-family: 'Raleway', sans-serif;
  line-height: normal;
  border-radius: 12px;
}

.buttonSection {
  margin-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rebeccapurple;
  /* gap: 44px; */
}
/* 
.buttonSection > button:nth-child(1) {
  flex: 1;
}

.buttonSection > button:nth-child(2) {
  flex: 2;
} */

.modalContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

.modalContainer > div:nth-child(1) {
  width: 100%;
  height: 349px;
  flex-basis: 349px;
}

.modalContainer > div:nth-child(1) > img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.modalContainer > div:nth-child(2) {
  background: #fff;
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  gap: 21px;
}

.modalContainer > div:nth-child(2) > h4 {
  color: #000;
  font-family: Lato;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.modalContainer > div:nth-child(2) > p {
  color: #000;
  font-family: 'Raleway', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.error {
  color: rgb(235, 81, 81);
  font-family: 'Raleway', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

@media screen and (max-width: 767px) {
  .preferredProject {
    margin-bottom: 1.5rem;
  }

  .preferredProject > p:nth-child(1),
  .error {
    font-size: 18px;
  }

  .preferredProject > p:nth-child(2) {
    font-size: 14px;
    margin: 16px 0;
  }

  .preferredProject > div {
    padding: 0.5rem 1rem;
    font-size: 14px;
  }

  .buttonSection {
    flex-direction: column;
    gap: 24px;
  }

  .buttonSection > button {
    flex: 1;
  }
}
