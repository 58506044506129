.container {
  min-height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 4rem;
  position: relative;
  width: 100%;
}

/* Main heading styling */
.container > h4 {
  color: var(--Dark-Main, #20252f);
  font-family: Lato;
  font-size: 56px;
  font-style: italic;
  font-weight: 300;
  line-height: 64px;
  width: 843px;
  text-align: center;
  margin-top: 2rem;
}

/* Positioning for the heading's inner span */
.container > h4 > span {
  position: relative;
}

/* Emphasizing the first span inside the heading */
.container > h4 > span > span:nth-child(1) {
  color: var(--Dark-Main, #20252f);
  text-align: center;
  font-family: Lato;
  font-size: 56px;
  font-style: italic;
  font-weight: 700;
  line-height: 64px;
}

/* Centering the SVG underline beneath the text */
.container > h4 > span > svg:nth-child(2) {
  position: absolute;
  left: -20px;
  right: -20px;
  margin: auto;
  bottom: -8px;
  width: 165px;
}

.featuredBlogLinkContainer {
  text-decoration: none;
}

.featuredBlogLinkContainer:hover img {
  transform: scale(0.99);
  transition: all 0.3s ease-in-out;
}

/* Layout for the blog container section */
.blogContainer {
  display: flex;
  justify-content: space-between;
  gap: 1.25rem;
  padding: 30px 95px 30px 0px;
  margin-top: 4rem;
}

/* Featured blog card styling */
.featuredBlog {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  max-width: 552px;
  height: 468px;
  background-color: #fff;
}

.featuredBlogImgContainer {
  width: 100%;
  height: 252px;
}

.featuredBlogImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.featuredBlogTitle {
  color: var(--Dark-Main, #20252f);
  font-family: Lato;
  font-size: 1.75rem;
  font-style: italic;
  font-weight: 700;
  line-height: 32px;
  margin-top: 1.75rem;
}

.featuredBlogDesc {
  color: var(--Dark-Main, #20252f);
  font-family: Lato;
  font-size: 1rem;
  font-style: italic;
  font-weight: 300;
  line-height: 24px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin: 1rem 0;
  text-overflow: ellipsis;
}

.featuredBlogLink {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  width: 100%;
}

.featuredBlogLink > span {
  color: var(--Dark-Main, #20252f);
  font-family: Lato;
  font-size: 0.875rem;
  font-style: italic;
  font-weight: 700;
  line-height: 24px;
}

.blogCard {
  display: flex;
  gap: 1rem;
  width: 100%;
  max-width: 552px;
  background-color: #fff;
  height: 227px;
}

.blogCardContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  gap: 1rem;
}

.blogCardImgContainer {
  width: 100%;
  max-height: 227px;
}

.blogCardTitle {
  margin-top: 0;
  color: var(--Dark-Main, #20252f);
  font-family: Lato;
  font-size: 1.25rem;
  font-style: italic;
  font-weight: 700;
  line-height: 32px;
}

.secondContainer {
  width: 100%;
  padding: 0 95px;
  text-align: left;
}

.secondContainer h4 {
  text-align: left;
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 1.25rem;
  position: relative;
  padding-bottom: 0.75rem;
}

/* Added fresh styling for the 'more articles' section */
.moreArticlesGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  width: 100%;
  margin-bottom: 3rem;
}

/* Styling for individual article cards */
.articleCard {
  display: flex;
  flex-direction: column;
  text-decoration: none;
}

.articleCard:hover img {
  transform: scale(0.98);
  transition: all 0.3s ease-in-out;
}

.articleImageContainer {
  width: 100%;
  height: 180px;
  margin-bottom: 1rem;
  overflow: hidden;
}

.articleImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Article title styling */
.articleTitle {
  color: var(--Dark-Main, #20252f);
  font-family: Lato;
  font-size: 1.1rem;
  font-weight: 700;
  line-height: 1.4;
  margin-bottom: 0.5rem;
}

/* Article description styling with a 2-line clamp */
.articleDesc {
  color: var(--Dark-Main, #20252f);
  font-family: Lato;
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 1.4;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 0.75rem;
}

/* Date styling for articles */
.articleDate {
  color: #6c757d;
  font-family: Lato;
  font-size: 0.75rem;
  font-weight: 400;
}

/* Pagination.module.css */
.paginationContainer {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  margin: 1rem 28rem;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
}

.paginationButton {
  padding: 0.5rem 0.75rem;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: none;
  background-color: transparent;
  font-size: 16px;
  color: #333;
  cursor: pointer;
  transition: background-color 0.2s;
}

.paginationButton.active {
  background-color: #e6e6e6;
  font-weight: 500;
}

.paginationArrow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: none;
  background-color: transparent;
  font-size: 16px;
  color: #333;
  cursor: pointer;
}

.paginationArrow:disabled {
  color: #ccc;
  cursor: not-allowed;
}
.paginationButton,
.paginationArrow {
  border-right: 1px solid #e0e0e0;
}

/* .paginationDots {
    display: none;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    padding: 0 1rem;
  }
  
  .paginationDot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #ccc;
    border: none;
    padding: 0;
    cursor: pointer;
  }
   */
.activeDot {
  background-color: #037ef3;
}

.mobileArrow {
  font-size: 14px;
  padding: 0;
  margin: 0 8px;
}

.maline {
  width: 90%;
  height: 2px;
  background-color: #e0e0e0;
  margin: 0.5rem 0;
}
/* Styling for the mobile header label */
.mobileLatestLabel {
  display: none;
}

@media screen and (max-width: 1024px) {
  .moreArticlesGrid {
    grid-template-columns: repeat(2, 1fr);
  }

  .secondContainer {
    padding: 0 50px;
  }
}

@media screen and (max-width: 767px) {
  .container {
    gap: 1.5rem;
    padding-top: 1rem;
    align-items: flex-start;
  }

  .container > h4 {
    font-size: 1.5rem;
    width: 100%;
    margin-top: 1rem;
    line-height: 1.3;
    text-align: center;
    padding: 0 1rem;
  }

  .container > h4 > span > span:nth-child(1) {
    font-size: 1.5rem;
    line-height: 1.3;
  }

  .container > h4 > span > svg:nth-child(2) {
    width: 4.5rem;
    bottom: -5px;
  }

  .blogContainer {
    flex-direction: column;
    gap: 1rem;
    padding: 0 1rem;
    margin-top: 1rem;
  }

  .blogCardContainer {
    display: none;
  }

  .mobileLatestLabel {
    display: block;
    font-size: 1rem;
    font-weight: 600;
    color: #333;
    margin-bottom: 0.5rem;
    padding: 0 1rem;
  }

  .featuredBlog {
    height: auto;
    max-width: 100%;
  }

  .featuredBlogImgContainer {
    height: 180px;
  }

  .featuredBlogTitle {
    font-size: 1.2rem;
    margin-top: 1rem;
    line-height: 1.3;
  }

  .featuredBlogDesc {
    font-size: 0.85rem;
    line-height: 1.4;
    margin: 0.5rem 0;
    -webkit-line-clamp: 2;
    line-clamp: 2;
  }

  .featuredBlogLink > span {
    font-size: 0.75rem;
  }

  .secondContainer {
    padding: 0 1rem;
  }

  .secondContainer h4 {
    font-size: 1rem;
    margin-bottom: 1rem;
    padding-bottom: 0.5rem;
  }
  .articleContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.25rem;
  }
  .articleContent {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  .moreArticlesGrid {
    grid-template-columns: 1fr;
    gap: 1.25rem;
  }

  .articleImageContainer {
    height: 130px;
    margin-bottom: 0.75rem;
  }

  .articleTitle {
    font-size: 0.9rem;
    margin-bottom: 0.25rem;
    line-height: 1.3;
  }

  .articleDesc {
    font-size: 0.75rem;
    line-height: 1.3;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    margin-bottom: 0.5rem;
  }

  .articleDate {
    font-size: 0.7rem;
  }

  /* Adjusting pagination spacing for mobile */
  .pagination {
    margin-top: 1rem;
  }

  /* Hiding standard pagination buttons on mobile in favor of dots */
  .paginationButton,
  .paginationArrow {
    display: none;
  }

  .paginationDots {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
  }

  .featuredBlogLink svg {
    width: 8px;
    height: 8px;
  }
  .paginationButton {
    display: none;
  }

  .paginationContainer {
    border: none;
    margin: 1rem 0;
  }

  .paginationArrow {
    width: 30px;
    height: 30px;
    font-size: 14px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
  }
  .maline {
    width: 100%;
  }
}
