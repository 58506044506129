.container {
  background-image: url('../../Assets/Images/spiral.svg');
  background-color: #fcefef;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 3rem;
}

.aboutContainer {
  padding: 150px 50px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.aboutText {
  width: 30%;
}
.aboutText > h4 {
  color: #000000;
  font-family: Regular;
  font-weight: 700;
  font-size: 32px;
  text-transform: uppercase;
}

.aboutText > p {
  color: #000000;
  font-size: 16px;
  font-style: italic;
  font-weight: 300;
}

.aboutText > p > span {
  font-weight: bold;
}

.aboutImage {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.aboutImage img,
.displayContainer > div > img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border: 2px solid black;
}

.displayContainer {
  padding: 50px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.focusContainer {
  padding: 200px 0px;
}

.focusTextSection {
  display: block;
  text-align: center;
}

.focusTextSection > h4 {
  font-size: 48px;
  font-family: Regular;
  font-weight: bold;
  text-transform: uppercase;
  color: #000000;
  margin-bottom: 1rem;
}

.focusTextSection > p {
  font-size: 16px;
  font-style: italic;
  font-weight: 300;
  width: 33%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
  line-height: 24.5px;
}

.focusTextSection > p > span {
  color: #008f4f;
  font-weight: 500;
}

.focusImageSection {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.NextSection {
  background-color: #fff;
  padding: 100px 50px;
  border-radius: 20px;
  margin-top: 3rem;
  margin-bottom: 2rem;
}

.NextTextSection {
  display: block;
  text-align: center;
}

.NextTextSection > h4 {
  font-size: 36px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 1rem;
  font-family: Regular;
}

.NextTextSection > p {
  font-size: 20px;
  font-weight: 300;
  font-style: italic;
  margin-bottom: 2rem;
  margin-right: auto;
  margin-left: auto;
  width: 55%;
}

.ExploreButton {
  background-color: #008f4f;
  padding: 10px 20px;
  border-radius: 10px;
  margin: auto;
  width: 20%;
}

.ExploreButton a {
  color: #fff;
  text-decoration: none;
  display: block;
  text-align: center;
  font-weight: bold;
}

@media screen and (min-width: 768px) and (max-width: 920px) {
  .aboutContainer {
    gap: 20px;
  }

  .aboutText {
    width: 50%;
  }

  .aboutImage img,
  .displayContainer > div > img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border: 2px solid black;
  }

  .NextTextSection > p {
    width: 80%;
  }

  .ExploreButton {
    width: 30%;
  }
}

@media screen and (max-width: 767px) {
  .aboutContainer {
    padding: 50px 20px 0px;
    display: block;
    gap: 0px;
  }

  .aboutText {
    width: 100%;
    margin-bottom: 2rem;
  }

  .aboutText > h4 {
    font-weight: 600;
    font-size: 24px;
    text-align: center;
  }

  .aboutText > p {
    font-size: 16px;
    width: 100%;
  }

  .displayContainer {
    padding: 20px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }

  .focusContainer {
    padding: 50px 0px;
  }

  .focusTextSection > h4 {
    font-size: 28px;
  }

  .focusTextSection > p {
    font-size: 16px;
    width: 90%;
  }

  .focusImageSection {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }

  .NextSection {
    padding: 50px 10px;
    border-radius: 20px;
  }

  .NextTextSection > p {
    width: 100%;
  }

  .ExploreButton {
    width: 50%;
  }
}
