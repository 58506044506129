.container {
  padding: 95px 2rem;
  background: #ecf5fc;
}

.volunteers {
  text-align: center;
}

.volunteers > h4 {
  font-family: 'Lato', sans-serif;
  font-size: 72px;
  font-weight: 600;
  line-height: 69.12px;
  color: #2d2d2d;
}

.volunteers > p {
  font-family: 'Raleway', sans-serif;
  font-size: 24px;
  font-weight: 300;
  line-height: 23.04px;
  color: #808080;
  margin-bottom: 1rem;
}
.testimonoalSection {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: 100px;
}

.testimonoalSection > div {
  max-width: 526px;
  max-height: 691px;
  border-radius: 32px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding: 4rem;
  background: #ffffff;
  border: 1px solid #000000;
}

.testimonoalSection > div:nth-child(2) {
  margin-bottom: 59px;
}

.testimonoalSection > div > img {
  width: 360px;
  height: 337.5px;
  border-radius: 774.42px 774.42px 0px 774.42px;
}

.testimonoalSection > div > div:nth-child(2) {
  padding: 10px;
  border-radius: 34px;
  border: 1px solid #000000;
  margin: 2rem 0;
}

.testimonoalSection > div > div:nth-child(3) {
  font-family: Raleway;
  font-size: 20px;
  font-weight: 500;
  line-height: 23.48px;
  text-align: center;
  color: #000000;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    padding: 20px;
  }

  .volunteers > h4 {
    font-size: 20px;
    line-height: 130.5%;
  }

  .volunteers > p {
    font-size: 16px;
    font-weight: 275;
    line-height: 140%;
  }

  .testimonoalSection {
    flex-direction: column;
    gap: 1rem;
    margin-top: 20px;
  }

  .testimonoalSection > div {
    max-width: 100%;
    max-height: auto;
    border-radius: 32px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding: 4rem;
    background: #ffffff;
    border: 1px solid #000000;
  }

  .testimonoalSection > div:nth-child(2) {
    margin-bottom: 59px;
  }

  .testimonoalSection > div > img {
    width: 320px;
    height: 317.5px;
    border-radius: 774.42px 774.42px 0px 774.42px;
  }

  .testimonoalSection > div > div:nth-child(2) {
    padding: 10px;
    border-radius: 34px;
    border: 1px solid #000000;
    margin: 1rem 0;
  }

  .testimonoalSection > div > div:nth-child(3) {
    font-size: 16px;
    line-height: 150%;
  }
}

@media screen and (max-width: 767px) {
  .container {
    padding: 20px;
  }

  .volunteers > h4 {
    font-size: 20px;
    line-height: 130.5%;
  }

  .volunteers > p {
    font-size: 16px;
    font-weight: 275;
    line-height: 140%;
  }

  .testimonoalSection {
    flex-direction: column;
    gap: 1rem;
    margin-top: 20px;
  }

  .testimonoalSection > div {
    max-width: 100%;
    max-height: auto;
    border-radius: 32px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding: 4rem;
    background: #ffffff;
    border: 1px solid #000000;
  }

  .testimonoalSection > div:nth-child(2) {
    margin-bottom: 59px;
  }

  .testimonoalSection > div > img {
    width: 320px;
    height: 317.5px;
    border-radius: 774.42px 774.42px 0px 774.42px;
  }

  .testimonoalSection > div > div:nth-child(2) {
    padding: 10px;
    border-radius: 34px;
    border: 1px solid #000000;
    margin: 1rem 0;
  }

  .testimonoalSection > div > div:nth-child(3) {
    font-size: 16px;
    line-height: 150%;
  }
}
