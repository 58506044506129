.container {
  min-height: calc(100vh - 10vh);
  background: url('../../Assets/Images/wavy.png');
  background-color: #fcefef;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.logoContainer {
  padding: 20px 0px 40px 40px;
}

.heroSection {
  display: flex;
  align-items: flex-end;
}

.heroTextSection {
  padding-left: 100px;
  border-bottom: 2px solid #008f4f;
  padding-bottom: 60px;
  width: 60%;
}

.heroTextSection > h4 {
  font-family: Regular;
  font-size: 56px;
  font-weight: 700;
  margin-bottom: 1rem;
  width: 70%;
  text-transform: capitalize;
  text-align: left;
}

.heroTextSection > p {
  font-style: italic;
  font-size: 22px;
  width: 60%;
  font-weight: 300;
  margin-bottom: 2rem;
}

.heroTextSection > div > button {
  width: 200px;
  background-color: transparent;
  border: 2px solid #000;
  color: #000;
  border-radius: 12px;
  box-shadow: 4px 4px 0px 0px #008f4f;
  padding: 0.5rem 1rem;
}

.colorTower {
  display: none;
}

.heroImage {
  margin-top: -190px;
}

.aboutSection {
  display: grid;
  align-items: flex-end;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto auto;
  align-items: center;
  justify-content: center;
  padding: 60px;
}

.aboutSection > h4 {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 40px;
  margin-bottom: 3rem;
  font-family: Regular;
  font-weight: 600;
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  align-self: start;
  margin-left: auto;
}

.aboutSection > p {
  font-style: italic;
  font-weight: 300;
  font-size: 22px;
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  align-self: end;
  width: 70%;
  text-align: right;
  margin-left: auto;
}

.highLight {
  color: #008f4f;
  font-weight: 500;
}

.aboutImageSection {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-row: 1 / 3;
  grid-column: 1 / 2;
}

.aboutImage > img {
  width: 500px;
}

.whySection {
  background: url('../../Assets/Images/wavy.png');
  background-color: #6b205e;
  border-radius: 20px;
  padding: 70px 0px 70px 70px;
  display: grid;
  grid-template-columns: 40% 60%;
  grid-template-rows: auto auto;
  align-items: center;
  justify-content: center;
}

.whySection > h4 {
  color: #fff;
  text-transform: uppercase;
  font-size: 40px;
  margin-bottom: 3rem;
  font-family: Regular;
  font-weight: 600;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  align-self: start;
}

.whySection > p {
  font-style: italic;
  font-weight: 300;
  color: #fff;
  display: block;
  text-align: left;
  width: 75%;
  margin-top: 3rem;
  margin-bottom: 3rem;
  grid-column: 1 / 2;
  grid-row: 2 / 3;
  align-self: end;
  font-size: 22px;
}

.whyImageSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-row: 1 / 3;
  grid-column: 2 / 3;
}

.partOne {
  display: flex;
  align-items: center;
}

.partOne > div {
  background-color: #fcefef;
  padding: 2px 2px 40px;
  width: 215px;
}

.partTwo {
  background-color: #fcefef;
  padding: 2px 2px;
  width: 215px;
  transform: rotate(10deg) translateY(70px);
  z-index: 10;
  box-shadow: 4px 4px 0px 0px #000;
}

.partTwo::after {
  content: '';
  position: absolute;
  bottom: 2px;
  left: 0;
  width: 100%;
  height: 19%;
  background-color: #ef8e21;
}

.partOne > div:nth-child(1) {
  transform: rotate(-20deg) translateY(80px);
  z-index: 1;
  position: relative;
  box-shadow: -4px 4px 0px 0px #000;
}

.partOne > div:nth-child(1)::after {
  content: '';
  position: absolute;
  bottom: 2px;
  left: 0;
  width: 100%;
  height: 19%;
  background-color: #008f4f;
}

.partOne > div:nth-child(2) {
  z-index: 1;
  position: relative;
  box-shadow: 4px 4px 0px 0px #000;
}

.partOne > div:nth-child(2)::after {
  content: '';
  position: absolute;
  bottom: 2px;
  left: 0;
  width: 100%;
  height: 19%;
  background-color: #6b205e;
}

.waitListSection {
  padding: 100px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.waitListSection > h4 {
  text-transform: uppercase;
  font-weight: 700;
  color: #000;
  font-family: Regular;
  font-size: 24px;
  margin-bottom: 1rem;
}

.waitListSection > p {
  font-style: italic;
  font-weight: 300;
  font-size: 16px;
  margin-bottom: 2rem;
  width: 58%;
  text-align: center;
}

.ourLCSection {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  flex-wrap: wrap;
}

.LC {
  border-radius: 8px;
  background: #ffde00;
  padding: 1rem;
  flex-basis: 20%;
  font-weight: 600;
  cursor: pointer;
  text-align: center;
}

@media screen and (min-width: 920px) and (max-width: 1120px) {
  .heroTextSection {
    width: 80%;
  }

  .heroTextSection > h4 {
    width: 80%;
  }

  .heroTextSection > p {
    font-style: italic;
    font-size: 22px;
    width: 75%;
    font-weight: 300;
    margin-bottom: 2rem;
  }

  .aboutSection > h4,
  .whySection > h4 {
    font-size: 35px;
  }

  .partOne > div {
    width: 155px;
  }
}

@media screen and (min-width: 767px) and (max-width: 920px) {
  .heroTextSection {
    width: 70%;
  }

  .heroTextSection > h4 {
    width: 100%;
    font-size: 35px;
  }

  .heroTextSection > p {
    font-style: italic;
    font-size: 22px;
    width: 100%;
    font-weight: 300;
    margin-bottom: 2rem;
  }

  .heroTextSection {
    padding-left: 50px;
  }

  .aboutSection > h4,
  .whySection > h4 {
    font-size: 30px;
  }

  .aboutSection > p {
    font-size: 18px;
    width: 85%;
  }

  .partOne > div {
    width: 130px;
  }

  .whySection > p {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .logoContainer {
    padding: 20px 0px 20px 20px;
  }

  .logoContainer > img {
    width: 100px;
  }

  .heroSection {
    align-items: center;
    flex-direction: column;
  }

  .heroTextSection {
    padding-left: 0px;
    padding-right: 20px;
    width: 100%;
    margin-bottom: 3rem;
    border-bottom: none;
  }

  .heroTextSection > h4 {
    font-size: 32px;
    width: 100%;
    text-align: center;
  }

  .heroTextSection > p {
    font-size: 20px;
    width: 95%;
    margin: 0 auto 4rem auto;
    text-align: center;
  }

  .heroTextSection > div {
    display: block;
    text-align: center;
  }

  .colorTower {
    display: block;
    margin: 0 auto;
    width: 100vw;
  }

  .colorTower > img {
    width: 100vw;
  }
  .colorblock {
    display: none;
  }

  .heroImage {
    margin-top: 0px;
  }

  .aboutSection {
    display: block;
    padding: 100px 20px;
  }

  .aboutSection > h4 {
    font-size: 30px;
    text-align: center;
  }

  .aboutSection > p {
    width: 90%;
    font-size: 18px;
    margin: 30px auto 0 auto;
    text-align: left;
  }

  .aboutImage > img {
    width: 400px;
    margin: 0 auto;
  }

  .whySection {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    border-radius: 12px;
    padding: 40px 20px;
  }

  .whySection > h4 {
    text-align: center;
    width: 100%;
    font-size: 30px;
  }

  .whySection > p {
    text-align: left;
    margin: 70px auto 0 auto;
    width: 75%;
    font-size: 18px;
  }

  .whyImageSection {
    margin-bottom: 70px;
  }

  .partOne > div {
    width: 150px;
  }

  .partTwo {
    width: 150px;
  }

  .waitListSection {
    padding: 40px 20px;
  }

  .waitListSection > p {
    font-size: 18px;
    width: 100%;
    text-align: left;
  }

  .LC {
    flex-basis: 46%;
  }
}

@media screen and (max-width: 450px) {
  .partOne > div {
    width: 135px;
  }

  .partTwo {
    width: 135px;
  }
}
