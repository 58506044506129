@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.container {
  margin-bottom: 30px;
  border: 1px solid #000000;
  border-radius: 32px;
  padding: 36px 56px;
}

.dropdownButton {
  height: 100%;
  display: flex;
  align-items: center;
  gap: 2rem;
  color: #2d2d2d;
  font-family: Lato;
  font-size: 32px;
  font-weight: 400;
  line-height: 38.4px;
  text-align: left;
  cursor: pointer;
  animation: appear 0.3s ease-in-out;
  user-select: none;
}

.dropdownButton svg:nth-child(3) {
  width: 24px;
  height: 24px;
  cursor: pointer;
  transition: all 0.2s;
  margin-left: auto;
  transition: all 0.3s ease-in-out;
}

.dropdownButton > span {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.dropdown .dropdownContent::-webkit-scrollbar {
  display: none;
}

.dropdownItem {
  margin: 10px 0px;
  cursor: pointer;
  transition: all 0.2s;
  position: relative;
  font-family: Lato;
  font-size: 20px;
  font-weight: 400;
  line-height: 140%;
  text-align: left;
  width: 100%;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  box-sizing: border-box;
}

.dropdownButton svg,
.dropdownButton svg:nth-child(3) {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  flex-grow: 0;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    padding: 20px;
  }

  .dropdownButton {
    height: 100%;
    display: flex;
    align-items: center;
    gap: 1rem;
    color: #2d2d2d;
    font-family: Lato;
    font-size: 20px;
    line-height: 150%;
  }

  .dropdownButton svg,
  .dropdownButton svg:nth-child(3) {
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    flex-grow: 0;
  }

  .dropdownItem {
    font-size: 16px;
    line-height: 150%;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 767px) {
  .container {
    padding: 20px;
  }

  .dropdownButton {
    height: 100%;
    display: flex;
    align-items: center;
    gap: 1rem;
    color: #2d2d2d;
    font-family: Lato;
    font-size: 20px;
    line-height: 150%;
  }

  .dropdownButton svg,
  .dropdownButton svg:nth-child(3) {
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    flex-grow: 0;
  }

  .dropdownItem {
    font-size: 16px;
    line-height: 150%;
    margin-bottom: 0;
  }
}
