.container {
  padding: 72px 120px;
  background-color: #08841b14;
}

.infoSection {
  margin-top: 72px;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-radius: 32px 0px 0px 32px;
}

.infoSection > div:nth-child(1) {
  width: 50%;
}

.infoSection > div:nth-child(1) > img {
  width: 100%;
  height: 100%;
  border-radius: 32px 0px 0px 32px;
}

.infoSection > div:nth-child(2) {
  padding: 83px 30px;
  background: #ffffff;
  width: 50%;
}

.infoSection > div:nth-child(2) > p {
  font-family: Raleway;
  font-size: 22px;
  font-weight: 400;
  line-height: 32px;
  text-align: justified;
  color: #000000;
  padding: 1rem 0;
}

.infoSection > div:nth-child(2) > p:nth-child(1) {
  border-bottom: 1px solid #202020;
}

.infoSection > div:nth-child(2) > button {
  margin-top: 2rem;
  width: 270px;
  padding: 10px 20px;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    padding: 22px 20px;
  }

  .infoSection {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    /* justify-content: center; */
    /* flex-direction: column; */
  }

  .infoSection > div:nth-child(1) {
    width: 100%;
  }

  .infoSection > div:nth-child(1) > img {
    width: 100%;
    height: 405px;
    border-radius: 32px;
    margin-bottom: 1rem;
  }

  .infoSection > div:nth-child(2) {
    padding: 0px;
    width: 100%;
    flex: 1;
  }

  .infoSection > div:nth-child(2) > p {
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
    line-height: 150%;
  }

  .infoSection > div:nth-child(2) > button {
    margin-top: 2rem;
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .container {
    padding: 22px 20px;
  }

  .infoSection {
    margin-top: 32px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .infoSection > div:nth-child(1) {
    width: 100%;
    flex-basis: 100%;
  }

  .infoSection > div:nth-child(1) > img {
    width: 100%;
    height: 405px;
    border-radius: 32px;
    margin-bottom: 1rem;
  }

  .infoSection > div:nth-child(2) {
    padding: 0px 15px;
    width: 100%;
  }

  .infoSection > div:nth-child(2) > p {
    font-family: Raleway;
    font-size: 14px;
    line-height: 150%;
  }

  .infoSection > div:nth-child(2) > button {
    margin: 2rem 0;
    width: 100%;
  }
}
