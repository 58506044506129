.container {
  min-height: calc(100vh - 10vh);
  background: url('../../Assets/Images/heroBg.svg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 40px 119px 80px 119px;
}

.upperSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3rem;
}

.upperSection > div > h4 {
  font-family: Lato;
  font-size: 96px;
  font-weight: 700;
  line-height: 115.2px;
  text-align: left;
  color: #ffffff;
}

.upperSection > div > p {
  font-family: Raleway;
  font-size: 24px;
  font-weight: 200;
  line-height: 28.18px;
  text-align: center;
  color: #ebebeb;
}

.ctaSection {
  display: flex;
  gap: 20px;
  margin-top: 50px;
}

.ctaSection > button {
  font-family: Raleway;
  width: 320px;
  padding: 10px 40px;
  border-radius: 32px;
  color: #f4faf5;
}

.ctaSection > button :hover {
  opacity: 0.7;
  transition: all;
}

.ctaSection > :nth-child(2) {
  width: 320px;
  background-color: transparent;
  border: 2px solid #f4faf5;
  color: #f4faf5;
}

.bottomSection > img {
  width: 100%;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    min-height: auto;
    padding: 20px 67px;
  }

  .upperSection {
    gap: 2rem;
    margin-bottom: 2rem;
    flex-direction: column;
  }

  .upperSection > div > h4 {
    font-size: 60px;
    line-height: 130.5%;
    text-align: center;
  }

  .upperSection > div > p {
    font-size: 20px;
    font-weight: 200;
    line-height: 140%;
  }

  .ctaSection {
    margin-top: 0px;
  }

  .ctaSection > button {
    width: 220px;
  }

  .ctaSection > :nth-child(2) {
    width: 200px;
  }
}

@media screen and (max-width: 767px) {
  .container {
    min-height: auto;
    padding: 20px 67px;
  }

  .upperSection {
    gap: 2rem;
    margin-bottom: 2rem;
    flex-direction: column;
  }

  .upperSection > div > h4 {
    font-size: 40px;
    line-height: 130.5%;
    text-align: center;
  }

  .upperSection > div > p {
    font-size: 20px;
    font-weight: 200;
    line-height: 140%;
  }

  .ctaSection {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  }
  .ctaSection > button {
    width: 220px;
  }

  .ctaSection > :nth-child(2) {
    width: 220px;
  }
}
